import React, { useRef, useState, useEffect } from 'react';
import { FaSearch } from 'react-icons/fa';
import './formulario.css';
import API from '../../providers/api';
import { Row, Col, Spinner, Input, Button, Badge } from 'reactstrap'
import logo from '../../assets/img/logo.png'
import bannerTop from '../../assets/img/banner-top.jpeg'
import success from '../../assets/img/success.png'
import fail from '../../assets/img/fail.png'
import { alert } from '../../helpers/alert';
import stateSelect from '../JsonData/state.json'
import moment from 'moment'
import DataTable from "react-data-table-component";

const Formulario = () => {

    const [numCertificado, setNumCertificado] = useState('')
    const [nitCliente, setNitCliente] = useState('')
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const [currentPage, setCurrentPage] = useState(1)
    const handlePageChange = (page) => {
        setCurrentPage(page)
    };

    const customStyles = {
        headCells: {
            style: {
                paddingLeft: '20px',
                paddingRight: '7px',
                backgroundColor: '#002240',
                color: 'white'
            },
        }
    };
    const columns = [
        {
            name: "Nit",
            selector: row => row.identificacion,
            width:'130px',
            wrap: true,
        },
        {
            name: "Nombre",
            selector: row => row.nombre_completo,
            sortable: true,
            wrap: true,
        },
        {
            name: "No. Certificado",
            selector: row => row.certificado,
            sortable: true,
            width:'150px',
            wrap: true,
        },
        {
            name: "Sistema",
            selector: row => row.nombre_equipos != undefined ? row.nombre_equipos : row.categoria_lv != undefined ? row.categoria_lv :  row.sistema,
            width:'130px',
            wrap: true,
        },
        {
            name: "Fecha instalación",
            selector: row => moment(row.fecha_instalacion).format('DD-MM-YYYY'),
            sortable: true,
            width:'150px',
            wrap: true,
        },
        {
            name: "Fecha inspección",
            selector: row => moment(row.fecha_inspeccion).format('DD-MM-YYYY'),
            sortable: true,
            width:'150px',
            wrap: true,
        },
        {
            name: "Fecha próxima inspección",
            selector: row => moment(row.fecha_inspeccion_proxima).format('DD-MM-YYYY'),
            sortable: true,
            width:'200px',
            wrap: true,
        },
        {
            name: "Días válidos",
            selector: row => row.total_dias,
            sortable: true,
            width:'140px',
            wrap: true,
        },
        {
            name: "Estado",
            selector: row => row.total_dias > 0 ? <Badge color="success">Vigente</Badge> : <Badge color="danger">Vencido</Badge>,
            wrap: true,
        },
    ]

    const buscarCertificados = () => {
        if(numCertificado == '' && nitCliente == '')
            return alert('Advertencia!', `Digite un número de certificado o la Identificación de la empresa`, 'warning', 'Cerrar');
        setLoading(true)
        API.buscarCertificados(nitCliente, numCertificado)
        .then(data => data.json())
        .then(dataJson => {
            if (dataJson.success == true) {
                setData(dataJson.data)
            }else{
                setData([])
            }
            limpiarDatos()
            setLoading(false)
        })
        .catch((e) => {
            console.log("Error =>", e)
        })
    }

    const limpiarDatos = () => {
        setNitCliente('')
        setNumCertificado('')
    }

    const reiniciar = () => {
        limpiarDatos()
        setData([])
    }

    const onChangeInputs = (e) => {
        if(e.target.name == 'cliente'){
            setNitCliente(e.target.value)
            setNumCertificado('')
        }else{
            setNitCliente('')
            setNumCertificado(e.target.value)
        }
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            if(numCertificado == '' && nitCliente == '')
                return alert('Advertencia!', `Digite un número de certificado o la Identificación de la empresa`, 'warning', 'Cerrar');
            setLoading(true)
            API.buscarCertificados(nitCliente, numCertificado)
            .then(data => data.json())
            .then(dataJson => {
                if (dataJson.success == true) {
                    setData(dataJson.data)
                }else{
                    setData([])
                }
                limpiarDatos()
                setLoading(false)
            })
            .catch((e) => {
                console.log("Error =>", e)
            })
        }
    };

    return (
        <div className='flex-container'>
            <div>
                <div className='cardForm' style={{background:'#002240'}}>
                    <div>
                        <Row>
                            <Col lg='6' style={{padding:'15px'}}>
                                <a href="https://fergoingenieria.com.co/" style={{padding:'10px'}}><img src={logo} alt='Logo fergo' style={{width:'100%',maxWidth:'150px'}} /></a>
                            </Col>
                            {/* <Col lg='6' md="12" style={{color:'white',fontSize:'18pt', Padding:'10px'}}><br/>
                                CONSULTA DE CERTIFICADOS
                            </Col> */}
                        </Row>
                    </div>
                </div>

                <div>
                    <div>
                        <Row>
                            <Col lg='12'>
                                <img src={bannerTop} alt='Banner Certificados' style={{width:'100%',maxWidth:'2048px',borderRadius:'0px 0px 20px 20px'}} />
                            </Col>
                        </Row>
                    </div>
                </div>

                <Row className='mt-4'>
                    <Col lg='4' md="12">
                        <label style={{color:'white'}}>ID. Cliente</label>
                        <Input value={nitCliente} name="cliente" placeHolder="Digite su número de identificación o NIT" onChange={e => onChangeInputs(e)} onKeyDown={handleKeyPress} />
                    </Col>
                    <Col lg='4' md="12">
                        <label style={{color:'white'}}>No. Certificado</label>
                        <Input value={numCertificado} name="certificado" placeHolder="Digite su número de certificado" onChange={e => onChangeInputs(e)} onKeyDown={handleKeyPress} />
                    </Col>
                    <Col lg='4' md="12">
                        <br/>
                        {!loading ? <Button color="success" onClick={buscarCertificados}><FaSearch /> Buscar</Button> : <Spinner color="success" />}
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col lg="12">
                        {
                            data.length > 0 ?
                                <DataTable
                                    data={data }
                                    columns={columns}
                                    customStyles={customStyles}
                                    pagination
                                    paginationDefaultPage={currentPage}
                                    onChangePage={handlePageChange}
                                />
                            : <div className='mt-4 alert alert-info text-center'>No hay registros para mostrar</div>
                        }
                    </Col>
                </Row>

                <div>
                    <Row className='mt-4 text-center text-white'>
                        <Col>
                            <span>
                                Fergo {moment().format('YYYY')}. Powered by <a href="https://ticservice.org/portal" target='_blank'>TIC Service</a>
                            </span>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default Formulario;